import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import { RadialChartBlock } from "../../../../../lib/annual-report/radial-chart-block/radial-chart-block";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AuthorBlock title={"Pam York"} subtitle={"Primary School Teacher Librarian"} mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "546px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d76f2aef9624dd068ef82aadc0fd0599/f066e/02.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUCAwQB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAFHppb4kp0oJ71d0qVkBD//xAAaEAADAAMBAAAAAAAAAAAAAAAAAQIDERIx/9oACAEBAAEFAkVgqYJK1wRocvh+iy2kf//EABcRAAMBAAAAAAAAAAAAAAAAAAECEBH/2gAIAQMBAT8BVdgM/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERAhD/2gAIAQIBAT8Bepxop//EABoQAAICAwAAAAAAAAAAAAAAABARAAESITH/2gAIAQEABj8CmRunfBuIpj//xAAcEAACAgMBAQAAAAAAAAAAAAABEQAhEDFBUWH/2gAIAQEAAT8hBlRkJYG34bhExC24d3ONCewsxoQ7vCmr9x//2gAMAwEAAgADAAAAEEfgPv/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QAazyMMZ7f//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QZYR02ZdIQZf/xAAcEAEAAwEAAwEAAAAAAAAAAAABABExIRBRcbH/2gAIAQEAAT8QUhrB5p6D0lQW14SPZKbIhY6pkCI0bErbAJ5HwUBB/LjtCkafC56iilp8Yz//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "02",
              "title": "02",
              "src": "/static/d76f2aef9624dd068ef82aadc0fd0599/f066e/02.jpg",
              "srcSet": ["/static/d76f2aef9624dd068ef82aadc0fd0599/f93b5/02.jpg 300w", "/static/d76f2aef9624dd068ef82aadc0fd0599/f066e/02.jpg 546w"],
              "sizes": "(max-width: 546px) 100vw, 546px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <AuthorBlock title={"Polina Spencer"} subtitle={"Secondary School Teacher Librarian"} mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "546px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e81c090b7bdb8b2f43c69ba94334a8ce/f066e/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAwL/xAAYAQACAwAAAAAAAAAAAAAAAAABAwACBP/aAAwDAQACEAMQAAABjPp14YhqMXs+s9TYHIGf/8QAHRABAAEEAwEAAAAAAAAAAAAAAgEAAxESBCIyE//aAAgBAQABBQKIzL4+ooelJm0zhWx0tDr8zQeIHjav/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQACEBL/2gAIAQMBAT8BoCZQJyT/xAAYEQEBAQEBAAAAAAAAAAAAAAABACECEf/aAAgBAgEBPwHtRjZ28v/EABwQAAEEAwEAAAAAAAAAAAAAABAAARExEiFhgf/aAAgBAQAGPwKFvxzTcGTqRR//xAAcEAACAwADAQAAAAAAAAAAAAAAAREhMUFRcZH/2gAIAQEAAT8higmbTwdEGAwtdVxzK2xbagXpT4G/Z+i4H4GhWvS7c9n/2gAMAwEAAgADAAAAEMwoMP/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEBH/2gAIAQMBAT8QcLIjyMAYf//EABgRAQEBAQEAAAAAAAAAAAAAAAEAESFR/9oACAECAQE/EOYZAG6a2PL/xAAfEAEBAAICAQUAAAAAAAAAAAABEQAhMUFhUXGhweH/2gAIAQEAAT8QcTlxEs3UF4cUKOkxxOZ165EQ6Epf3IUkAJiuVdauvbECD2lYfTjt2hFIuKLGFDofJhDJb8sFC2Rn/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/e81c090b7bdb8b2f43c69ba94334a8ce/f066e/01.jpg",
              "srcSet": ["/static/e81c090b7bdb8b2f43c69ba94334a8ce/f93b5/01.jpg 300w", "/static/e81c090b7bdb8b2f43c69ba94334a8ce/f066e/01.jpg 546w"],
              "sizes": "(max-width: 546px) 100vw, 546px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`The 2020 - 2021 school year has seen many necessary changes to the library operations and services.`}</p>
    </blockquote>
    <p>{`The priority for the library was to ensure the safety of our patrons whilst also continuing to provide educational and recreational resources and services. The library had to adjust to operate under the current restrictions.`}</p>
    <p>{`The primary library operated a mobile system with three mobile trolleys for different grade level groups. The secondary library was able to open its doors to cohorts upon sign up. Along with this, all students and parents were encouraged to use the ‘hold’ function through Destiny Discover to request materials to then be delivered to homerooms.`}</p>
    <p>{`In order to ensure library materials were safe for use, all returned items have been placed in quarantine for 72 hours before being re shelved.`}</p>
    <p>{`Follett Destiny, our operational system rose to this challenge by creating a quarinite hold status for all returned materials which were released for further use after the quarantine period ended. We also purchased two high intensity portable UV-C sanitisers for materials which were needed urgently.`}</p>
    <p>{`During lockdown periods, the librarians were able to move forward with beginning a diversity audit in the primary library and the genrefication of the fiction collection in the secondary library.`}</p>
    <p>{`Circulation for the period between 1 April 2020 and 31 March 2021 was unsurprisingly lower than previous years given the amount of on campus closure throughout the year. 10,105 resources were circulated however we had a record number of 365 holds placed compared to 9 in the 2019 - 2020 school year. Our highest month for circulation was October 2020 as shown in the graph below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/31b481121b69438d1558ee94d3005688/9239a/chart-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACH0lEQVQoz32T20tVQRSH/TujoIfqoYsRVEIiQheKOqGFHU2kUitPJpmpx0ueMpHoIUhMkhB6iaIHbd/O2XPd+4uZ49lphAuGmb327G9+a89vteV5zt7QWhMEAdvb24Rh6OfWemdnpxj/C8dqc4t6vY4QwiccMEkSwjDy+TiOiaLI5/6OOlmW+WFtc24J80BrrU/yj9r9p+9/Njbn609L2MgLdQUwTVOklD7pwMZYhMqI0+YhdZHx8J3i4zfDyqZh5pNmbk1z8lHKhy0DOKW7QAcpFAKJyNn8YZldMwy+VSx/MSxtGI4MpJSXFBcrgq4JyY1pyalhwfst67+z2S7QXYBTKIT0ia1flmNDKaWq5PxTQedzQc+c5MyIYKCm6BwXXH0puV2VnB0VzK9rNr5blNkDNMaQ55bFdc3rz5r2EcHdBUnXhODypKRvUXk1DnhpXHBlUnJrVnq1/TXFoXKKUEXJIKQEqzg+JLhfayrrnW8BBX2LktPDqX/XVCgozUo6KoLBN8pXVACb3nM/1nJ0UFKuadpHJaWqpuOZomtC0TuvOfFAcm9Jc6Gi6H6huD6tOfdE+f2H+yVC77kUH5mmezygshpxcypkeDniTjWkby7k8UrMtcmIsdWYnpmQ8kLEUC2i9CpgbDWic+w3qTT7gS1DJ0lMEsfU6811HEeEQeC7w80tczvDx1FUGN85pSg5P8DQrU5yrddoNA7c51rvD1k3bQS5/JqLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart 1",
            "title": "chart 1",
            "src": "/static/31b481121b69438d1558ee94d3005688/c1b63/chart-1.png",
            "srcSet": ["/static/31b481121b69438d1558ee94d3005688/5a46d/chart-1.png 300w", "/static/31b481121b69438d1558ee94d3005688/0a47e/chart-1.png 600w", "/static/31b481121b69438d1558ee94d3005688/c1b63/chart-1.png 1200w", "/static/31b481121b69438d1558ee94d3005688/9239a/chart-1.png 1246w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b8bf8488092d1f557939c57f9b9c4107/c929c/chart-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "96%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAAB4ElEQVQ4y52US4+qQBCF5///ockM/QBkMBdhM4izc+Eb40KNSmKiZ3IaGgGZzL13USke3R+HU9X1cr/fURQFdrsd1us1NptNGXluMp9NJl/IsgnSNMU4TfH5mWI8zpBlGabTKfI8x3a7xe12wwuBh8MBURTBcRwopSCVgvv6CiWlufc8z4TvM/w6+Mx1XQghEAQBrtdrCTwej0iSpNzMha6LwHFMtpt+Cvt+OBw+gFQYx/FDjVLweU2lvwAZWmuEYdhW2AJKCU/rvwJy/RNwv9/XQL8BMuBf1PUCT6cTkjg2xRi8v8PVuga6/wNsKhxQXbWQSr2O+XXuAFtFocKYVRbC/LL5ugVWH3iKhre9CkdxDC1lCeRiArWu77tKfdpR9ecT8Hw+l31Y9V5Tid/no9bGa6u+BQSAg1VIYMcrbiR08Pb2sKJSx+e+ENBs7CaQZznuALte1X3J64ZqQp8UmqJEEbQQ5uj1VpWK7AkivPLZeKwUQltlAi9FgWQ06lfYVErv7K/bIjEL0QaeWJQwNNOlT1kLYNuq+SEhMPz4aCi8XMxJsWf5p1/uZjONOJ2EwB/PawM5DznXaDDB/xJSSgRNhezFxWJhpvNsNjN5Pp+bSczMyd2Xl8tlHavVynC+ASNzCDnsWyH4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart 2",
            "title": "chart 2",
            "src": "/static/b8bf8488092d1f557939c57f9b9c4107/c1b63/chart-2.png",
            "srcSet": ["/static/b8bf8488092d1f557939c57f9b9c4107/5a46d/chart-2.png 300w", "/static/b8bf8488092d1f557939c57f9b9c4107/0a47e/chart-2.png 600w", "/static/b8bf8488092d1f557939c57f9b9c4107/c1b63/chart-2.png 1200w", "/static/b8bf8488092d1f557939c57f9b9c4107/c929c/chart-2.png 1218w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This school year 1,452 books have been weeded from the library following the guidelines in the Library Policies and Procedures Handbook. Our current collection consists of 24,464 items, representing 21,323 individual titles. The value of this collection is estimated at $271,516.84, which contains a valid approximation of the cost for copies that do not contain price information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      